<template>
	<v-container class="mt-4 ml-2" fluid>
	  <v-row v-if="( getdatosUsuario.idpuesto == 18 && [568,7 ].includes( getdatosUsuario.iderp ) ) || getdatosUsuario.idpuesto != 18">
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1">Objetivos Semanales</span>
	  		    <v-spacer></v-spacer>

            <v-btn
              color="orange"
              dark
              class="mr-2"
              @click="dialog = true"
              small
              tile
              v-if="!director"
            >
              <v-icon left small>mdi-plus</v-icon>
              Agregar
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>

	  		  </v-card-title>
	  		  <v-card-text class="black--text">
	  		  	<v-row>
	  		  		<v-col cols="12" md="4" lg="2">
	  		  			<v-text-field
	  		  			  label="Fecha"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  type="date"
	  		  			  v-model="fecha"
	  		  			></v-text-field>

	  		  			<div class="mt-2"><b>Fechas:</b> {{ fechaini }} al {{ fechafin }}</div>
	  		  		</v-col>

	  		  		<v-col cols="12" md="6" lg="5">
		  		  		<v-autocomplete 
		  		  			filled 
		  		  			dense 
		  		  			:clearable="director ? false : true" 
		  		  			v-model="usuario" 
		  		  			:items="usuarios"
									label="Selecciona un usuario" 
									hide-details
									item-text="datos_completos" 
									item-value="id_usuario"
									:readonly="director || getdatosUsuario.iderp == 954 ? false : true"
								>
								</v-autocomplete>
	  		  		</v-col>
	  		  	</v-row>

	  		  	<!-- Estatus -->
	  		  	<v-row>
              <v-col cols="12" md="4" lg="2" class="align-center text-center">
                <v-card class="shadowCard pa-2">
                  <b>objetivos</b>    
                  <br/>
                  {{ objetivos.length }}
                  <br/>
                  <br/>
                  <b>Realizadas</b>     
                  <br/>
                  {{ objetivos.filter( el => el.cumplio == 1 ).length }}
                  <v-progress-linear
                    :value="(objetivos.filter( el => el.cumplio == 1 ).length / objetivos.length ) * 100 "
                    color="deep-purple accent-4"
                  ></v-progress-linear>
                  <br/>
                  <b>Sin realizar</b>   
                  <br/>
                  {{ objetivos.filter( el => !el.cumplio ).length }}
                  <v-progress-linear
                    :value="(objetivos.filter( el => !el.cumplio == 1 ).length / objetivos.length ) * 100 "
                    color="primary"
                  ></v-progress-linear>
                  <br/>
                  <b>% Cumplimiento</b> 
                  <br/>
                  {{ objetivos.filter( el => el.cumplio == 1 ).length ? ( (objetivos.filter( el => el.cumplio == 1 ).length / objetivos.length)*100 ).toFixed(1) : 0 }}
                  <v-progress-linear
                    :value="objetivos.filter( el => el.cumplio == 1 ).length ? ( (objetivos.filter( el => el.cumplio == 1 ).length / objetivos.length)*100 ).toFixed(1) : 0 "
                    :color="colorCumplimiento"
                  ></v-progress-linear>
                </v-card>
              </v-col>

              <v-col cols="12" md="8" lg="10">

			  		    <v-row>
			  		    	<v-col cols="12">
			  		    		<v-card class="shadowCard">
			  		    		  <v-card-text class="black--text">
											  <v-data-table
											    :headers="headers"
											    :items="objetivos"
											    class="elevation-0"
											    :search="search"
											    :mobile-breakpoint="100"
							            dense
											  >
											    <!-- Valores que ocupan botons, chips, iconos, etc -->
											    <template v-slot:item.activo_sn="{ item }">
											      <v-chip 
											      	v-if="item.activo_sn == 1"
											      	small
											      	color="green"
											      	dark
											      >Si</v-chip>

											      <v-chip 
											      	v-else
											      	small
											      	color="error"
											      	dark
											      >No</v-chip>
											    </template>

											    <template v-slot:item.cumplio="{ item }">
											    	<v-checkbox 
											    		v-model="item.cumplio" 
											    		:value="1"
											    		@change="updateActividad( item ) "
											    	></v-checkbox>
											    </template>
											  
											    <template v-slot:item.actions="{ item }">
											    	<v-btn 
											    		color="error" 
											    		x-small
											    		@click="deleteItem(item)"
											    		class="mr-2"
											    	>
												      <v-icon small> mdi-delete</v-icon>
											    	</v-btn>
											    </template>

											    <template v-slot:no-data>
											      <v-btn
											        color="primary"
											        @click="initialize"
											        small
											      >
											        Actualizar
											      </v-btn>
											    </template>

											  </v-data-table>
			  		    		  </v-card-text>
			  		    		</v-card>
			  		    	</v-col>
			  		    </v-row>
              </v-col>
	  		  	</v-row>

	  		  </v-card-text>
	  		</v-card>
	  	</v-col>
	  </v-row>

	  <div v-else>Este apartado está en consturcción</div>

	  <!-- Dialogo de editar o agregar-->
	  <v-dialog
      v-model="dialog"
      max-width="600px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
          	<strong>ID: {{ editedItem.idobjetivos_semanales }}</strong>
          </span>
        </v-card-title>

        <v-card-text>
          <v-row>

          	<v-col cols="12" md="6" lg="4">
              <v-text-field
                v-model="editedItem.fecha"
                label="Fecha"
                filled
                type="date"
                hide-details
                dense
                :readonly="editedIndex > -1 ? true :false "
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              <v-textarea
                class="mt-2"
                filled
                dense
                clearable
                v-model="editedItem.objetivo"
                label="Ingresa tu objetivo"
                :readonly="editedIndex > -1 ? true :false "
                hide-details
                :rows="3"
                auto-grow
              >
              </v-textarea>

            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="black"
            tile
            dark
            small
            @click="close"
          >
            <v-icon small left>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            class=""
            tile
            dark
            small
            @click="save"
          >
            <v-icon small left>mdi-content-save</v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
      	<v-card-text>
	      	<v-row justify="space-around">
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
		      				<strong>Eliminar</strong> ¿Estás seguro que deseas eliminar la información?
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text  class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#5C5C5C"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="5">
	      			<v-img src="@/assets/borrar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {

    mixins: [ validarErrores, funcionesExcel ],

  	components:{
      Alerta,
      carga,
    },

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,

			fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

      editedIndex: -1,

      editedItem: {
        idobjetivos_semanales:0,
				id_usuario:0,
				actividad:'',
				hora_inicio:'',
				hora_final:'',
				cumplio:0,
				objetivo:'',
				objetivos:'',
				enfoque:'',
				kpi:'',
				fecha_creacion:null,
				fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
				estatus:0,
      },

      defaultItem: {
        idobjetivos_semanales:0,
				id_usuario:'',
				actividad:'',
				hora_inicio:'',
				hora_final:'',
				cumplio:0,
				objetivo:'',
				objetivos:'',
				enfoque:'',
				kpi:'',
				fecha_creacion:null,
				fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
				estatus:0,
      },

      // tabla
			search:'',
      objetivos: [],
      headers: [
        { text: 'Objetivo'      , value: 'objetivo'    },
        { text: '¿Cumplió?'     , value: 'cumplio'          },
        { text: 'Actions'       , value: 'actions', sortable: false },
      ],


      objetivo:'',
			usuarios:[],
      usuario: null,

      fechaini: null,
      fechafin: null,
      cicloInf: null,
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar Objetivo' : 'Editar Objetivo'
      },
      
      colorCumplimiento( ){

        let cumplimiento = this.objetivos.filter( el => el.cumplio == 1 ).length ? ( (this.objetivos.filter( el => el.cumplio == 1 ).length / this.objetivos.length)*100 ) : 0 

        if( cumplimiento <= 33 ){
          return 'red'
        }else if( cumplimiento <= 90 ){
          return 'orange'
        }else{
          return 'green'
        }
      },


      emojisCalifica( ){

        let cumplimiento = this.objetivos.filter( el => el.cumplio == 1 ).length ? ( (this.objetivos.filter( el => el.cumplio == 1 ).length / this.objetivos.length)*100 ) : 0 

        if( cumplimiento <= 33 ){
          return '😰'
        }else if( cumplimiento <= 90 ){
          return '🫠'
        }else{
          return '🤩'
        }
      },


      textoAvance( ){

        let cumplimiento = this.objetivos.filter( el => el.cumplio == 1 ).length ? ( (this.objetivos.filter( el => el.cumplio == 1 ).length / this.objetivos.length)*100 ) : 0 

        if( cumplimiento <= 0 ){
          return 'Aún no empiezas 😡'
        }else if( cumplimiento <= 33 ){
          return 'Mirada que juzga 😑'
        }else if( cumplimiento <= 50 ){
          return 'Aún te falta mucho 🫤'
        }else if( cumplimiento <= 75 ){
          return 'Animóooooo Bruce! Bruce! Bruce! 😁'
        }else if( cumplimiento <= 90 ){
          return 'Ya meritoooo 🙂'
        }else{
          return 'Lo lograste, eres un Campeón 🤩'
        }
      },


      director( ){

      	return this.getdatosUsuario.iderp == 1 ? true : false

      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },


    },

    async created () {

    	if( !this.director ){
    		this.usuario = this.getdatosUsuario.iderp
    	}

      await this.getUsuarios()
      await this.initialize()
    },

    methods: {
      initialize () {
      	this.cargar = true
        this.objetivos = []

        const payload = {
        	id_usuario: this.usuario,
        	fecha:      this.fecha
        }

        return this.$http.post('objetivos.get', payload).then(response=>{
          this.objetivos       = response.data.objetivos
          this.fechaini        = response.data.fechaini
          this.fechafin        = response.data.fechafin
        	this.cargar          = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getUsuarios( ){
      	this.usuarios = []
    		this.$http.get('usuarios.all.erp').then(response=>{
    			for(const i in response.data){
    				response.data[i].datos_completos = response.data[i].datos_completos.toUpperCase() 
    			}

          let usuarios = [334,28,169,32,966,954,568,31]

    			this.usuarios = response.data.filter( el => usuarios.includes( el.id_usuario ) )
    		}).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {
        this.editedIndex = this.objetivos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.objetivos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
      	this.cargar = true
        // Lo mandapos por el EP
        this.editedItem.deleted = 1
        this.$http.put('objetivos.update/' + this.editedItem.idobjetivos_semanales, this.editedItem).then(response=>{
        	this.cargar = true
        	this.closeDelete()
        	this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
      	if(!this.editedItem.objetivo){
      		return this.validarErrorDirecto('Favor de llenar el objetivo')
      	}
      	// Cargamos al usuario que hizo el cambio
      	this.editedItem['id_usuario'] = this.getdatosUsuario.iderp
	      this.cargar = true

        if (this.editedIndex > -1) {
	        // Lo mandapos por el EP
          this.$http.put('objetivos.update/' + this.editedItem.idobjetivos_semanales, this.editedItem).then(response=>{
          	this.validarSuccess( response.data.message )
          	this.initialize()
        		this.close()
	        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

        } else {
	        // Lo mandapos por el EP
          this.$http.post('objetivos.add', this.editedItem).then(response=>{
          	this.initialize()
            this.editedItem.hora_inicio = this.editedItem.hora_final
            this.editedItem.actividad   = ''
            this.editedItem.objetivo    = 0
	        	this.cargar = false
        		// this.close()
	        }).catch( error =>{
          	this.validarError( error.response.data.message )
        	}).finally( () => { this.cargar = false })
        }
      },

      updateActividad( item ){
      	this.cargar = true

      	this.$http.put('objetivos.update/' + item.idobjetivos_semanales, item).then(response=>{
        	this.validarSuccess( response.data.message )
        	this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },

    },
  }
</script>


